var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"pageTable"}},[_c('v-container',{attrs:{"grid-list-xl":"","fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":"12"}},[_c('data-table',{ref:"tabla",attrs:{"tableName":'Categoría empleado',"url":_vm.url,"columns":_vm.columns,"filters":_vm.filters,"showAdvancedFilters":false,"perPage":[10,25,50,100]},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
return _c('tbody',{},_vm._l((data),function(item){return _c('tr',{key:item.id},[_c('td',[_c('div',[_vm._v(_vm._s(item.clave))])]),_c('td',[_c('div',[_vm._v(_vm._s(item.nombre))])]),_c('td',[_c('div',[_vm._v(_vm._s(item.descripcion))])]),_c('td',[_c('div',{staticClass:"tblOpciones"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"botonHover",attrs:{"icon":"","fab":"","dark":"","small":""},on:{"click":function($event){return _vm.abrirModal('update', item)}}},on),[_c('v-icon',{staticClass:"tamIconoBoton invertirColorBotones"},[_vm._v("edit")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"botonHover",attrs:{"icon":"","fab":"","dark":"","small":""},on:{"click":function($event){return _vm.eliminar(item)}}},on),[_c('v-icon',{staticClass:"tamIconoBoton invertirColorBotonesDelete"},[_vm._v("delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Eliminar")])])],1)])])}),0)}}])},[_c('template',{slot:"actionButtonsLeft"},[_c('div',{staticClass:"botonAgregar"},[_c('v-btn',{staticClass:"btnAdd",attrs:{"color":"#004BAF","right":"","rounded":""},on:{"click":function($event){return _vm.abrirModal('add')}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"dark":""}},[_vm._v("add")]),_vm._v("Agregar "),_c('div',{staticClass:"mr-4"})],1)],1)])],2)],1)],1)],1),[_c('v-dialog',{attrs:{"persistent":"","max-width":"600px","scrollable":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"botonModal",staticStyle:{"display":"none"},attrs:{"id":"btnModal"}},on))]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('perfect-scrollbar',[_c('v-card',{attrs:{"elevation":"0"}},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-card-title',{staticClass:"justify-center card_titulo"},[_c('div',{staticClass:"headerModal"},[_c('img',{staticClass:"imgModal",attrs:{"src":_vm.imagen}}),_c('h2',{staticClass:"titleModal"},[_vm._v(_vm._s(_vm.tituloModal))])])]),_c('v-card-text',{staticClass:"card_texto"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoading),expression:"isLoading"}],attrs:{"id":"padre"}},[_c('div',{attrs:{"id":"loading"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":100,"width":7,"color":"blue"}})],1)]),_c('v-container',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isLoading),expression:"!isLoading"}],attrs:{"grid-list-md":"","id":"contenedor"}},[_c('v-divider',{staticClass:"divider"}),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","xs":"12","sm":"6","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Clave","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"pa-0 ma-0",attrs:{"outlined":"","label":"Clave","error-messages":errors,"required":""},model:{value:(_vm.categoria.clave),callback:function ($$v) {_vm.$set(_vm.categoria, "clave", $$v)},expression:"categoria.clave"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","xs":"12","sm":"6","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"pa-0 ma-0",attrs:{"outlined":"","label":"Nombre","error-messages":errors,"required":""},model:{value:(_vm.categoria.nombre),callback:function ($$v) {_vm.$set(_vm.categoria, "nombre", $$v)},expression:"categoria.nombre"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","xs":"12","sm":"12","md":"12"}},[_c('ValidationProvider',{attrs:{"name":"Descripcion","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"pa-0 ma-0",attrs:{"outlined":"","label":"Descripción","error-messages":errors,"required":""},model:{value:(_vm.categoria.descripcion),callback:function ($$v) {_vm.$set(_vm.categoria, "descripcion", $$v)},expression:"categoria.descripcion"}})]}}],null,true)})],1)],1)],1)],1),_c('v-card-actions',{staticClass:"d-flex justify-end card_accion"},[_c('button',{staticClass:"btnCerrar",attrs:{"disabled":_vm.isSaving},on:{"click":function($event){return _vm.cerrarModal()}}},[_vm._v(" Cancelar ")]),_c('v-btn',{staticClass:"btnGuardar",attrs:{"disabled":invalid,"loading":_vm.isSaving},on:{"click":function($event){return _vm.guardar()}}},[_vm._v(" Guardar ")])],1)]}}])})],1)],1)],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }